import clsx from 'clsx'
import Circle from 'modules/common/components/_ui/Circle/Circle'
import { PropsWithChildren } from 'react'

interface Props {
  name?: string
  variant?: 'plain' | 'circle'
  className?: string
}
export const NameInitial: React.FC<PropsWithChildren<Props>> = ({
  name,
  variant,
  className,
}) => {
  const userInitials = name ? name[0] : ''

  if (variant === 'circle') {
    return (
      <Circle className={clsx('uppercase', className)}>{userInitials}</Circle>
    )
  }

  return <p className={clsx('uppercase', className)}>{userInitials}</p>
}
