import { useAppContextState } from 'modules/common/contexts/AppContext'
import { EnumRoutes } from 'modules/common/routes'
import React from 'react'
import { Link } from '../Link/Link'
import { HeaderMenu } from './HeaderMenu'
interface Props {
  redirectURL?: string
  logoPath?: string
  withUser?: boolean
}

export const Header: React.FC<Props> = ({
  withUser = false,
  redirectURL,
  logoPath = '/assets/svg/logo.svg',
}) => {
  const { brand } = useAppContextState()

  const defaultRedirectUrl = withUser
    ? EnumRoutes.HOME
    : (process.env.NEXT_PUBLIC_MARKETING_SITE_ROOT as string)

  return (
    <div className="sticky top-0 z-30 flex h-header w-full flex-row items-center justify-between border-b border-info-100 bg-white px-5">
      <Link href={redirectURL || defaultRedirectUrl}>
        <div className="flex">
          <img src={logoPath} alt={brand} className="h-5" />
        </div>
      </Link>
      {withUser && <HeaderMenu />}
    </div>
  )
}
