import LayoutSimple from 'modules/common/components/_layouts/LayoutSimple'
import { Button } from 'modules/common/components/_ui/Button/Button'
import { useTranslation } from 'modules/common/hooks/useTranslation'
import { Trans } from 'next-i18next'
import Image from 'next/image'
import React, { useEffect, useState } from 'react'
import { Link } from '../Link/Link'

const LayoutMobileProtected: React.FC = () => {
  const { t } = useTranslation('common')
  const [url, setUrl] = useState('')

  useEffect(() => {
    setUrl(window.location.origin)
  }, [])

  return (
    <LayoutSimple>
      <div className="box-content flex w-full max-w-xs flex-col items-center justify-center space-y-8 px-6 py-10">
        <Image width="117" height="181" src="/assets/img/mobile-app.png" />
        <div className="text-center text-info-600">
          <Trans parent="h2" className="py-5 text-center text-2xl font-bold">
            {t('mobile.title')}
          </Trans>
          <Trans parent="p">{t('mobile.text')}</Trans>
        </div>
        <Link href={`${url}/telechargement/app-mobile`} className="w-full">
          <Button variant="contained" color="active" className="w-full">
            {t('mobile.button')}
          </Button>
        </Link>
      </div>
    </LayoutSimple>
  )
}

export default LayoutMobileProtected
