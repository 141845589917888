import clsx from 'clsx'
import { PropsWithChildren } from 'react'
import { Skeleton } from '../Skeletons/Skeleton/Skeleton'

interface Props {
  className?: string
}
const Circle: React.FC<PropsWithChildren<Props>> = ({
  children,
  className,
}) => (
  <div
    className={clsx(
      'flex h-10 w-10 items-center justify-center rounded-full bg-info-600 font-headings text-sm font-bold ring-2 ring-white drop-shadow-lg filter',
      className
    )}
  >
    <p className="text-sm text-white">{children}</p>
  </div>
)

interface CircleSkeletonProps {
  className?: string
}

export const CircleSkeleton = ({ className }: CircleSkeletonProps) => {
  return (
    <Skeleton
      className={clsx('h-10 w-10 rounded-full ring-2 ring-white', className)}
    />
  )
}

export default Circle
