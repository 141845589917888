import clsx from 'clsx'
import {
  Button,
  ButtonBaseProps,
  ButtonDesignOptionProps,
} from '../Button/Button'

export type DropdownMenuItemProps = ButtonBaseProps &
  Partial<ButtonDesignOptionProps>
export const DropdownMenuItem: React.FC<DropdownMenuItemProps> = ({
  children,
  className,
  ref: _,
  variant = 'none',
  color = undefined,
  ...props
}) => {
  return (
    <Button
      variant={variant}
      color={color}
      {...props}
      className={clsx(
        'flex w-full justify-start px-2 py-4 font-headings text-sm font-bold',
        className
      )}
    >
      {children}
    </Button>
  )
}
