import clsx from 'clsx'
import { useLogoutFlow } from 'modules/auth/flows/useLogoutFlow'
import { useAuthenticatedUserQuery } from 'modules/auth/queries/useAuthenticatedUser.query'
import { useDropdownMenu } from 'modules/common/hooks/useDropdownMenu'
import { useTranslation } from 'modules/common/hooks/useTranslation'
import { getFullName } from 'modules/common/lib/helpers/names/getFullName'
import { useRef } from 'react'
import { NameInitial } from '../NameInitial/NameInitial'
import { IconExpandMore, IconLogOut } from '../_icons/icons'
import { Button } from '../_ui/Button/Button'
import { DropdownMenu } from '../_ui/DropdownMenu/DropdownMenu'
import { DropdownMenuItem } from '../_ui/DropdownMenu/DropdownMenuItem'

export const HeaderMenu = () => {
  const { t } = useTranslation(['common'])
  const ref = useRef(null)

  const { data: authenticatedUser } = useAuthenticatedUserQuery()
  const { logout, loading } = useLogoutFlow()

  const firstName = authenticatedUser?.firstname
  const lastName = authenticatedUser?.lastname
  const userName = getFullName(firstName, lastName)

  const {
    registerDropdownMenu,
    registerDropdownButton,
    registerDropdownMenuItem,
    isOpen,
  } = useDropdownMenu({ triggerOffset: 4 })

  return (
    <div className="relative pl-2" ref={ref}>
      <Button
        {...registerDropdownButton()}
        variant="none"
        className="flex flex-row items-center"
      >
        {firstName && (
          <NameInitial variant="circle" name={firstName} className="mr-2" />
        )}
        <p className="pt-1 align-middle font-headings text-sm font-bold uppercase text-info-600">
          {userName || t('myaccount')}
        </p>
        <IconExpandMore
          className={clsx(
            'mt-1 transition-transform',
            isOpen && 'rotate-180 transform'
          )}
        />
      </Button>
      <DropdownMenu className="rounded-t-none" {...registerDropdownMenu()}>
        <DropdownMenuItem
          variant="link"
          color="danger"
          isLoading={loading}
          icon={<IconLogOut />}
          {...registerDropdownMenuItem({ onClick: logout })}
        >
          {t('disconnect')}
        </DropdownMenuItem>
      </DropdownMenu>
    </div>
  )
}
