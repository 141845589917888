import clsx from 'clsx'
import { AnimatePresence, motion } from 'framer-motion'
import { DropdownMenuProps } from 'modules/common/hooks/useDropdownMenu'
import { forwardRef } from 'react'

export const DropdownMenu = forwardRef<HTMLDivElement, DropdownMenuProps>(
  ({ children, style, isOpen, renderLayer, className }, ref) => {
    return renderLayer(
      <AnimatePresence>
        {isOpen && (
          <motion.div
            className={clsx(
              'z-40 flex max-w-max flex-col divide-y divide-gray-100 rounded-lg border border-info-100 bg-white px-2 text-info-600 drop-shadow-lg filter',
              className
            )}
            ref={ref}
            style={{ ...style, minWidth: 200 }}
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.9 }}
            transition={{ duration: 0.1 }}
          >
            {children}
          </motion.div>
        )}
      </AnimatePresence>
    )
  }
)
