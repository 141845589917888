import { ReactNode, ReactPortal, useState } from 'react'
import { LayerProps, useLayer, UseLayerOptions } from 'react-laag'
import { DropdownMenuItemProps } from '../components/_ui/DropdownMenu/DropdownMenuItem'

export interface DropdownMenuProps extends LayerProps {
  isOpen: boolean
  renderLayer: (children: ReactNode) => ReactPortal | null
  className?: string
  children?: React.ReactNode
}

export const useDropdownMenu = (options?: Partial<UseLayerOptions>) => {
  const [isOpen, setIsOpen] = useState(false)

  const closeMenu = () => setIsOpen(false)

  const { triggerProps, layerProps, renderLayer } = useLayer({
    auto: true,
    onOutsideClick: closeMenu,
    placement: 'bottom-end',
    possiblePlacements: [
      'bottom-start',
      'bottom-end',
      'bottom-center',
      'top-start',
      'top-center',
      'top-end',
    ],
    triggerOffset: 8,
    ...options,
    isOpen,
  })

  return {
    registerDropdownButton: () => ({
      ...triggerProps,
      onClick: (e: any) => {
        e.stopPropagation()
        setIsOpen((prev) => !prev)
      },
    }),
    registerDropdownMenu: (): DropdownMenuProps => ({
      ...layerProps,
      isOpen,
      renderLayer,
    }),
    registerDropdownMenuItem: (
      props?: Pick<DropdownMenuItemProps, 'onClick'>
    ) => ({
      onClick: async (event: any) => {
        event.stopPropagation()

        if (props?.onClick) {
          await props.onClick(event)
        }
        closeMenu()
      },
    }),
    closeMenu,
    isOpen,
  }
}
